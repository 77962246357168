/**
 * Alien6 Theme v2 2021
 * copyright (c) www.alien6.com
 *
 * Homepage
  * Issue: font size must be adaptative
 */
//@import 'components/decorator';

$background-image: '/assets/images/bg_index.jpg';
$background-image: '/assets/images/bg2.png';

div.home {
    background: rgba(20,46,50,0.4);
    overflow: hidden;
    background-image: linear-gradient(to bottom, rgba(20,46,50,0.6), rgba(20,46,50,0)), url($background-image);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 5vh 5vw;
    max-width: 100vw;

    // Responsive
    height: 100vh !important;
    
    @include respond-to(zero, small) {
        padding: 2.5vh 5vw;
    }
    @include respond-to(zero, medium, landscape) {
        overflow-y: auto;
    }

    // Heading Row
    .home-head {
        color: $white;
        font-size: $text-medium;

        img {
            max-height: 8vh;
            width: unset !important;
        }

        p {
            text-align: right;

            // Responsive
            @include respond-to(zero, x-small) {
                display: none;
            }
            @include respond-to(x-small, medium) {
                text-align: center;
            }
        }
    }

    @include respond-to(zero, small) {
        bottom: 1vh;
        font-size: $text-small;
        width: 100%;
        text-align: center;
        left: auto;
    }

    // Content
    .home-content {
        margin-top: 5rem;
        height: 100%;

        // Responsive
        @include respond-to(zero, large) {
            margin-top: 1rem;
        }

        h1.title {
            color: $white;
            //font-family: 'Minbus';
            font-size: $title-xlarge;
            font-weight: 500;
            z-index: 50;

            &:after {
                font-family: 'Poppins';
                content: "Paris | Enghien-les-Bains, France";
                display: block;
                font-size: $title-x-small;
                font-weight: 300;
            }

            // Responsive
            @include respond-to(zero, small) {
                font-size: $title-small;

                &:after {
                    font-size: $title-xx-small;
                }
            }
            @include respond-to(small, medium, landscape) {
                font-size: $title-small;
                text-align: center;

                &:after {
                    font-size: $title-xx-small;
                }
            }
            @include respond-to(medium, large, landscape) {
                font-size: $title-medium;
                text-align: center;
            }
            @include respond-to(small, medium, portrait) {
                font-size: $title-medium;
                text-align: center;
            }
            @include respond-to(medium, large, portrait) {
                font-size: $title-large;
                text-align: center;
            }
        }
        .desc {
            text-align: justify;
            color: $white;
            font-size: $text-large;
            font-weight: 300;
            z-index: 1000;

            >div {
                p {
                    &:last-of-type {
                        margin-top: 2rem;
                    }
                }
            }

            // Responsive
            @include respond-to(zero, small) {
                font-size: $text-small;
            }
            @include respond-to(small, large, landscape) {
                font-size: $text-small;
            }
            @include respond-to(small, large, portrait) {
                font-size: $text-medium;
            }
            @include respond-to(x-large, xx-large) {
                font-size: $text-xlarge;
            }
        }
        a.btn {
            margin-top: 5em;
            background-color: $green-700;
            color: $white;

            // Responsive
            @include respond-to(zero, medium, landscape) {
                margin-top: .5em;
            }
            @include respond-to(zero, small, portrait) {
                margin-top: 2.5em;
            }
            @include respond-to(x-large, xx-large) {
                font-size: $text-xlarge;
            }
        }
        a.btn:hover {
            background-color: $green-500;
        }
    }

    // Footer
    .home-foot {
        position: absolute;
        bottom: 10vh;
        z-index: 50;
        left: 10vw;
        display: inline;

        a, a:link{
            color: $secondary-green-100;
        }
        a:hover, a:active {
            color: $secondary-green-400;
        }
        // Responsive
        @include respond-to(zero, small) {
            display: none;
        }
        @include respond-to(small, medium, landscape) {
            display: none;
        }
        @include respond-to(x-large, xx-large) {
            font-size: $text-xlarge;
        }
    }
}