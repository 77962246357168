.neon {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;

    @keyframes breathe {
        0% {
        transform: scale(1);
        color: hsla(320deg, 100%, 95%, 0.3);
        }
        100% {
        transform: scale(0.9);
        color: hsla(320deg, 100%, 95%, 0.8);
        }
    }

    canvas {
        width: 100%;
        height: 100%;
        box-shadow: 0 1em 2em 0.5em hsla(210deg, 35%, 0%, 0.1)
    }
}