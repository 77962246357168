.safearea.error-404 {
    margin-top: 140px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
    align-items: center;
    padding-bottom: 5rem;
    > div .btn {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  
  .error {
    .square {
      width: 140px;
      height: 140px;
      background-color: #fff;
    }
  
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    width: max-content;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  
    p {
      font-size: 180px;
      font-weight: 600;
      color: #fff;
      margin: 0;
    }
  }
  
.safearea.error-404 > div {
    width: 100%;
    > p {
      text-align: center;
      margin-bottom: 5rem;
      font-size: 1.1rem;
      color: #fff;
    }
}