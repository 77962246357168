/**
 * Cases Catalogue Index
 */
 .articles {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 40px;
	align-items: center;
	padding-bottom: 5rem;

	h1 {
		font-size: $title-large;
		font-weight: 700;
		max-width: 750px;
		word-break: break-word;
		margin-bottom: 4.5rem;
	}

	.card {
		box-shadow: 0 0 10px rgba(0,0,0,0.1);
		border-radius: 10px;
		.card-img-top {
			width: 100%;
			height: 15vw;
		}
		.card-header {
			padding: 1rem;
			.bg-image {
				width: 100%;

				.card-img-top {
					min-height: 200px;
				}
			}
		}
		.card-body {
			.card-title {
				text-align: center;
				margin: 1rem 0;

				&.title {
					overflow : hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
				}
			}
			.card-text {
				text-align: justify;
				font-size: $text-medium;
				font-weight: 300;
				&.crop {
					height: 100px;
					overflow : hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 4;
				}
			}
			.card-footer {
				text-align: right;
			}
		}

		&::after {
			content: "";
			width: 334px;
		}
	}
}

/**
 * Article
 */
.article {
	margin: 2rem 0;
	padding-bottom: 5rem;
	text-align: justify;

	img {
		max-width: 100%;
	}

	h1 {
		font-size: $title-large;
		font-weight: 700;
		max-width: 750px;
		word-break: break-word;
		margin-bottom: 2.5rem;
		text-align: left;

		@media screen and (max-width: 767px) {
			font-size: 3.5rem;
		}

		@media screen and (max-width: 500px) {
			font-size: 2.5rem
		}
	}
	h2,	h3,	h4 {
		margin: 3rem 0;
	}

	h2 {
		&::before {
			content: "§";
			font-size: 1.5rem;
			font-family: "Roboto", sans-serif;
			color: $primary-600;
			margin-right: .5rem;
			display: inline-block;
			transform: translateY(-.25rem);
		}
	}
	a {
		color: $primary;
	}

	.social-card {
		max-width: 380px;

		// Responsive
		@include respond-to(zero, medium) {
			.img-fluid {
				display: none;
			}
		}
	}

	blockquote {
		background: #f9f9f9;
		border-left: 7px solid #ccc;
		margin: 1.5em 10px;
		padding: 0.5em 10px;
		margin-bottom: 2rem;
	}
}

/**
* Fixed Sidebar
*/
#scrollspy.sticky-top {
	top: $navbar-height;
	margin-top: 1rem;

	.nav-item.active {
		background-color: transparent;
		box-shadow: none;
		border-left: 0.125rem solid $primary-600 ;
		border-radius: 0;
	}

	.nav-link.active {
		color: $primary-600 !important;
		border-left: .125rem solid $primary-600;
	}
}