.partners {
	// Because of navbar size
	margin-top: calc(2.2rem + 60px);
	gap: 30px;
	display: flex;
	flex-wrap: wrap;
	padding: 5em;

	.partner {
		margin-top: 2rem;
		// To center logos
		.card-header {
			display: flex;
			height: 150px;
			align-items: center;
			justify-content: center;
			img {
				margin: auto;
				max-width: 100%;
				max-height: 150px;
			}
		}

		h1 {
			margin-bottom: 4.5rem;
			font-size: 4.5rem;
			font-weight: 700;
			max-width: 750px;
		}
		p {
			text-align: justify;
		}
	}
}

@media screen and (max-width: 900px) {
	.partners {
		padding: 1em;
		h1 {
			font-size: 3rem;
			text-align: center;
		}
	}
}
@media screen and (max-width: 650px) {
	.partners {
		h1 {
			font-size: 2rem;
			text-align: center;
		}
	}
}