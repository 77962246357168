/**
 * Particles.js theming
 */
.particles {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 2;
}
