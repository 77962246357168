.quotes {
    padding: 4rem 0;
    display: flex;
    &.left {
        justify-content: flex-start;
        > div {
            width: 70%;
        }
    }
    &.right {
        justify-content: flex-end;
        > div {
            width: 70%;
        }
    }
    &.full {
        > div {
            width: 100%;
        }
    }
    > div {
        h6 {
            display: flex;
            align-items: center;
            gap: 12px;
            color: #fff;
            margin-bottom: 0;
            .rect {
                width: 20px;
                height: 3px;
                background-color: #fff;
            }
        }
        > div {
            position: relative;
            margin-bottom: 2rem;
            svg {
                enable-background:new 0 0 32 32;
                width: 60px;
                position: absolute;
                top: -25px
            }
            p {
                color: #fff;
                text-indent: 80px;
                line-height: 2.1rem;
                font-style: italic;
            }
        }
        p {
            text-indent: 32px;
            color: #eee;
            margin: 0 !important;
        }
    }
}

@media screen and (max-width: 900px) {
    .quotes {
        > div {
            > div {
                margin-bottom: 1rem;
                svg {
                    enable-background:new 0 0 32 32;
                    width: 40px;
                    position: absolute;
                    top: -20px;
                }
                p {
                    font-size: .8rem;
                    line-height: 1.5rem;
                    text-indent: 50px;
                }
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .quotes {
        &.right > div , &.left > div {
            width: 100%;
        }
    }
}