/**
 * Hero Section
 */
.case {
    section.hero {
        position: relative;
        padding-bottom: 3rem;

        div.hero-image {
            position: absolute;
            height: 100vh;
            width: 100%;
            filter: brightness(.8);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            img {
                object-fit: cover;
                min-width: 100%;
                min-height: 100vh;
            }
        }

        div.hero-content {
            padding-top: 65vh;
            position: relative;
            color: $white;
            width: 1100px;
            max-width: 90%;
            margin: 0 auto;
        }
    }
}

@media screen and (max-width: 900px) {
    .case {
        section.hero{
            .hero-content {
                padding-top: 40vh;
            }
        }
    }
}