/**
 * Fade Effect
 */
#fader {
	background-color: $secondary-blue-900;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	z-index: 99999;
	pointer-events: none;
	animation-duration: 600ms;
	display: flex;
	justify-content: center;
	align-items: center;
	animation-timing-function: ease-in-out;
}

@keyframes fade-out {
	from { opacity: 1 }
	to { opacity: 0 }
}

@keyframes fade-in {
	from { opacity: 0 }
	to { opacity: 1 }
}

#fader.hide {
	opacity: 0;
}

#fader.fade-out {
	opacity: 0;
	animation-name: fade-out;
}

#fader.fade-in {
	opacity: 1;
	animation-name: fade-in;
}