footer {
    position: relative;
    background-color: $dark-blue;
    color: $white;
    padding: 2rem;


    .top {
        display: flex;
        justify-content: space-between;

        .socials-section {
            align-items: center;
        }

        h5 {
            font-size: $text-large;
            font-weight: 600;
        }

        > div {
            flex: 1;
        }
        > div:nth-child(1) {
            p {
                font-size: $text-small;
            }
        }
        > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            align-items: center;
            ul {
                margin-top: .5rem;
                padding-left: 0;
                text-align: center;
                li {
                    display: block;
                    a {
                        color: $blue-600;
                    }
                }
            }
        }
        > div:nth-child(3) {
            display: flex;
            flex-direction: column;
        }
        > div > img {
            width: 130px;
            max-width: 100%;
        }
        .socials {
            display: flex;
            gap: .5rem;
            svg {
                height: 24px;
                width: 24px;
                fill: $white;
                opacity: 1;
                transition: all .5s;
            }
            &:hover {
                svg {
                    opacity: .8;
                }
                a:hover svg {
                    opacity: 1;
                    fill: $blue-600;
                }
            }
        }
    }

    @include respond-to(zero, small) {
        .top {
            flex-direction: column;
            gap: 2rem;
            margin-bottom: 2em;

            > div {
                align-items: center !important;
                display: flex;
                flex-direction: column;
                p {
                    text-align: center;
                }
            }
        }
    }
}