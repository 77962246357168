.case  {
    .constraints {
        padding-left: 6vw;
        display: flex;
        gap: 6rem;
        flex-wrap: wrap;
        margin-top: 4rem;

        &:before {
            width: calc(33.33333333% - 4rem);
            content: "";
        }
        > div {
            flex: 0 0 calc(33.33333333% - 4rem);
            border-top: 2px solid $primary;
            color: $white;
            position: relative;

            p {
                font-size: .9rem;
                font-weight: 300;
                text-align: justify;
            }
            .image {
                width: 100%;
                margin-top: 1rem;
                margin-bottom: 0;
                overflow: hidden;
                z-index: 10;

                img {
                    width: 100%;
                    z-index: 1;
                    transform: scale(1);
                    transition: transform .6s;
                }
                &:hover img {
                    transform: scale(1.1);
                }
            }
        }
    }
}


@media screen and (max-width: 900px) {
    .case {
        .constraints {
            padding-left: 0;
            gap: 2rem;

            &:before {
                width: 0;
                content: none;
            }
            > div {
                flex: 0 0 calc(100% - 1rem) !important;
                h5 {
                    word-break: break-all;
                    font-size: 1rem;
                }
                p {
                    font-size: .8rem;
                }
            }
        }
    }
}