.navbar {
	// padding: 1.1rem 0;
	// transition: padding .5s;

	.navbar-brand img {
		width: 150px;
		height: auto;
	}

	.nav-link.active {
		color: $primary-600 !important;
	}

	@media screen and (max-width: 400px) {
		.navbar-brand img {
			height: 40px;
			width: auto;
		}
	}

	@include respond-to(x-small, medium, portrait) {
		padding: .4rem 0;

		.navbar-brand img {
			height: 40px;
			width: auto;
		}
	}

	@include respond-to(x-small, medium, landscape) {
		padding: .4rem 0;

		.navbar-brand img {
			height: 40px;
			width: auto;
		}
	}
}

.navbar.down {
	// padding: 0.5rem 0;
	// border-bottom: 1px solid $primary-900;

	@include respond-to(x-small, medium, portrait) {
		padding: 0;
	}

	@include respond-to(x-small, medium, landscape) {
		padding: 0;
	}
}