/**
 * Service Catalogue Index
 */
 :root {
	--xdeg: 7;
	--ydeg: -19;
	--zdeg: -0;
  }

.service-catalogue {
	padding: 5em;
	background-color: $white;
	h1 {
		margin-bottom: 4.5rem;
		font-size: $title-large;
		font-weight: 700;
		max-width: 750px;
	}
	.card {
		.card-title {
			color: $white;
			margin: 0 1em;
			text-align: center;
		}
		.card-number {
			color: $white;
            font-family: 'Poppins';
            font-size: $title-large;
            font-weight: 500;
		}

		transform: rotateY(0deg);
		transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);
		will-change: transform;
		transform-style: preserve-3d;
		transition: all 1s;
	}

	.card:hover {
		box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
		z-index: 3;
		transform: perspective(1000px) rotateX(7deg) rotateY(-19deg)
		  scale3d(1.1, 1.1, 1.1);
		transform: perspective(1000px) rotateX(calc(var(--xdeg) * 1deg))
		  rotateY(calc(var(--ydeg) * 1deg)) scale3d(1.1, 1.1, 1.1);
	  }

	// Responsive
	@include respond-to(zero, large) {
		h1, h2, h3, h4, h5, h6 {
			text-align: center;
		}
	}
    @include respond-to(zero, small) {
		h1 {
			font-size: $title-small;
		}
    }
    @include respond-to(small, large) {
		h1 {
			font-size: $title-medium;
		}
	}
}

.container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    flex-wrap: wrap;
    z-index: 1;
}

/**
 * Service
 */
.service {
	font-size: 1.2rem;
	font-weight: 200;
	color: $white;

	h1 {
		margin-bottom: 4.5rem;
		font-size: 4.5rem;
		font-weight: 700;
		max-width: 750px;
		color: $white;
	}
	h2 {
		font-size: 3.5rem;
		font-weight: 700;
		margin-bottom: 3.5rem;
		max-width: 90%;
		color: $white;
	}
	h3 {
		font-size: 2rem;
		font-weight: 600;
		margin-bottom: 2rem;
		color: $white;
	}
	h4, h5, h6 {
		margin-top: 1rem;
		font-size: 1.2rem;
		font-weight: 500;
		text-transform: uppercase;
		color: $primary;
	}
	p {
		max-width: 680px;
		margin-top: 2rem;
		text-align: justify;

		&.right {
            padding-left: 25%;
        }
        &.left {
            padding-right: 25%;
        }
	}
	section {
		position: relative;
		margin-top: -2rem;
        margin-bottom: 4rem;
        padding: 2rem 0;

		&:before {
			content: "";
			border-top: .4rem solid $white;
			position: absolute;
			left: 0;
			top: 0;
			height: .4rem;
			padding-top: 2rem;
		}

		&.full {
            &:before {
                width: 100%;
            }
        }

		&.left {
            &:before {
                width: 70%;
            }
            .items {
                padding-left: 0;
				&:before { content: none; }
				div { flex: 0 0 calc(50% - 3rem); }
            }
        }

		&.right {
            flex-direction: row-reverse;

			&:before {
                width: 70%;
                left: unset;
                right: 0;
            }
             &.sticky .sticky {
                align-items: flex-start;
            }
            .items {
                padding-left: 0;
				:before { content: none; }
				div { flex: 0 0 calc(50% - 3rem); }
            }
			h2 {
                left: 30%;
                max-width: 70%;
            }
        }
		&.sticky {
            display: flex;
            > div:first-of-type {
                width: 70%;
                > h2 {
                    font-size: 4.5rem;
                    color: #fff;
                    font-weight: 700;
                    width: 550px;
                    max-width: 90%;
                }
            }
            .sticky {
                width: 30%;
                position: sticky;
                top: 8rem;
                right: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                height: max-content;
                gap: 2rem;
                > div {
                    h4 {
                        color: #1E96D1;
                        font-size: .95rem;
                        text-transform: uppercase
                    }
                    ul {
                        padding-left: 0;
                        li {
                            display: block;
                            color: #fff;
                            font-size: 1rem;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
	}
}

@media screen and (max-width: 900px) {
    .service {
	   font-size: .8rem;
       h1 {
			font-size: 3rem;
			text-align: center;
		}
		h2 {
			font-size: 2rem;
		}
		h3 {
			font-size: 1.5rem;
		}
		h4, h5, h6 {
			font-size: .8rem;
		}
		section {
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
	}
}

@media screen and (max-width: 650px) {
    .service {
		font-size: .8rem;
		h1 {
			font-size: 2.5rem;
			text-align: center;
		}
		h2 {
			font-size: 2rem !important;
			max-width: 100% !important;
			left: 0 !important;
		}
		h3 {
			font-size: 1.5rem;
		}
		h4, h5, h6 {
			font-size: .8rem;
		}
		section {
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
		&.sticky {
			.sticky {
				width: 100%;
			}
		}
    }
}

/**
 * Effects
 */
 @keyframes separation {
    0% {
        width: 90%;
    }
    100% {
        width: 100%;
    }
}

@keyframes globe {
    0% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1.1);
    }
}