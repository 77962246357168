/**
 * Alien6 Theme v2 2021
 * copyright (c) www.alien6.com
 *
 * This version relies on the recommended
 * 7-1 Architecture
 */
@import 'abstracts/variables',
        'abstracts/colors',
        'abstracts/functions',
        'abstracts/mixins',
        'abstracts/placeholders';

// @import 'vendors/bootstrap';
// @import 'vendors/jquery-ui';

@import 'base/reset',
        'base/typography',
        'base/base',
        'base/animation',
        'layout/navigation',
        'layout/grid',
        'layout/header',
        'layout/footer',
        'layout/sidebar',
        'layout/forms',
        'components/buttons',
        'components/carousel',
        'components/cover',
        'components/dropdown',
        'components/cards',
        'components/form-checks',
        'pages/home',
        'pages/customer_cases',
        'pages/services',
        'pages/articles',
        'pages/cgvu',
        'pages/legal',
        'pages/partners',
        'pages/contact',
        'pages/404',
        'themes/theme';