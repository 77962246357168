.case {
    section.mission {

        .image {
            width: 100%;
            margin: 3rem 0;

            img {
                width: 100%;
            }

            &.right {
                justify-content: flex-end;
                display: flex;
            }
            &.left img,
            &.right img {
                width: 75%;
            }

            &.no-margin {
                margin: 0 !important;
            }
        }

        p {
            text-align: justify;
            margin-top: 1rem;
            color: $white;

            &.right {
                padding-left: 25%;
            }
            &.left {
                padding-right: 25%;
            }
        }

        &.sticky {
            display: flex;
            > div:first-of-type {
                width: 70%;
            }
            .sticky {
                width: 30%;
                position: sticky;
                top: 8rem;
                right: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                height: max-content;
                gap: 2rem;
                > div {
                    ul {
                        padding-left: 0;
                        li {
                            display: block;
                            color: #fff;
                            font-size: 1rem;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .case {
        section.mission {
            &.left {
                flex-direction: column-reverse;
                &:before {
                    width: 100%;
                }
            }
            &.right {
                flex-direction: column-reverse;
                &:before {
                    width: 100%;
                }
            }
            &.sticky {
                > div:first-of-type {
                    width: 100%;
                }
                .sticky {
                    width: 100%;
                    position: relative;
                    top: 0;
                    align-items: flex-start;
                    margin-bottom: 3rem;
                    flex-direction: row;
                    flex-wrap: wrap;
                }
            }
        }
    }
}