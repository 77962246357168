// table > thead > tr > th {
//   text-align: center;
//   padding: .2em;
//   border-color: $primary;
// }

// table > tbody > tr > td {
//   text-align: left;
//   padding: .2em;
//   border-color: $dark;
//   border-style: solid;
//   border-width: 1px;
// }