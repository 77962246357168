/**
 * Vivus.js theming
 */
.vivus {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    svg {
        max-height: 100vh;
        max-width: 100vh;

        // Responsive
        @include respond-to(zero, large) {
            .hide-sm {
                display: none;
            }
        }

    }
}

