section.cgvu  {
	margin-top: $navbar-height;
	color: $white;
	padding: 4em 0 8em 0;
	text-align: justify;
	font-weight: 100;

	// Responsive
	@include respond-to(zero, small) {
		padding: 2rem;
		overflow: hidden;
	}

	h1,	h2,	h3,	h4 {
		margin: 0.7em 0;
	}
	p {
		margin: 1.2rem 0;
	}
}