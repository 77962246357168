
.case {
    section.infos {
        display: flex;

        > div:first-of-type {
            width: 70%;
            hr {
                border: 0 solid $primary;
                color: $primary;
                width: 70%;
                opacity: 1;
                animation: separation 3s alternate infinite;
            }
            > div {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                flex-wrap: wrap;
                ul {
                    padding-left: 0;
                    li {
                        display: block;
                        color: $white;
                    }
                }
            }
        }
        > div:last-of-type {
            width: 30%;
            display: flex;
            justify-content: center;
            align-items: flex-start;

            > div {
                width: 70%;
                border-radius: 50%;
                animation: globe 3s alternate infinite;
                overflow: hidden;
                background-color: $primary;
                visibility: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    opacity: 0
                }
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .case {
        section.infos {
            flex-direction: column;
            align-items: center;
            gap: 30px;
            > div:first-of-type {
                width: 100%;
                > div {
                    flex-wrap: wrap;
                    > div {
                        min-width: calc(50% - 10px);
                        width: max-content;
                    }
                }
            }
            > div:last-of-type {
                width: 100%;
            }
        }
    }
}