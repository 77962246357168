.cards {
    display: flex;
    gap: 1.5rem;
    margin-top: 5rem;
    flex-wrap: wrap;
    width: 100%;

    > div {
        width: calc(33.33333% - 1rem);
        color: $white;

        .image {
            width: 100%;
            height: 525px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                object-fit: contain;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 900px) {
.cards {
        flex-direction: column;
        align-items: center;
        > div {
            width: 400px;
            max-width: 100%;
            .image {
                max-height: 75vh;
            }
        }
    }
}