/**
 * Alien6 Theme v2 2021
 * copyright (c) www.alien6.com
 *
 * Legal Notice
  * Issue: font size must be adaptative
 */

section.privacy-policies {
	margin-top: $navbar-height;
	color: $white;
	padding: 4em 0 8em 0;
	text-align: justify;
	font-weight: 100;

	// Responsive
	@include respond-to(zero, small) {
		padding: 2rem;
		overflow: hidden;
	}

	h1,	h2,	h3,	h4 {
		margin: 0.7em 0;
	}
	table {
		border-collapse: collapse;
		margin-left: 2rem;
		width: 100%;
		table-layout: fixed;

		// Responsive
		@include respond-to(zero, small) {
			margin-left: 0;

			thead {
				position: absolute;
				height: 1px;
				overflow: hidden;
				padding: 0;
			}
			tr {
				display: block;
				margin-bottom: .625em;
			}
			td {
				display: block;
				font-size: $text-small;
				text-align: center;
			}
			td:last-child {
				border-bottom: 0;
			}
		}
	  }
	p {
		margin: 1.2rem 0;
	}
}