.users {
    &.left {
        h1, h2, h3 {
            float: left;
            width: 70%;
        }
    }
    &.right {
        h1, h2, h3 {
            float: right;
            width: 70%;
        }
    }
}