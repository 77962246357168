.contact-section {

    // margin-top: 50px; // to be replaced by menubar height
	color: $white;
	padding: 4em 0 8em 0;
	text-align: justify;

    .thanks-title {
        text-align: center;
        padding-bottom: 20px;
        color: #00b0b0;
    }

    .form-control~.form-label {
        color: $white;
    }
    .form-control:focus~.form-label {
        color: $primary;
    }
    .form-control:focus {
        border-color: $primary;
        -webkit-box-shadow: inset 0 0 0 1px $primary;
        box-shadow: inset 0 0 0 1px $primary;
        color: $white;
    }

    .form-outline .form-control:focus~.form-notch .form-notch-middle {
        border-color: $primary;
    }

    .form-outline .form-control:focus~.form-notch .form-notch-leading {
        border-color: $primary;
    }

    .form-outline .form-control:focus~.form-notch .form-notch-trailing {
        border-color: $primary;
    }

}