$carousel-height: 200px;

section.news {
  position: relative;
  // Because of navbar size
  margin-top: $navbar-height;
  height: $carousel-height;
  z-index: 1;

  // Responsive
  @include respond-to(zero, medium) {
    margin-top: $navbar-height-medium;
  }
  @include respond-to(medium, large) {
    margin-top: $navbar-height-large;
  }

  .swiper-container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    height: 150px;
    width: 1200px;
    max-width: 70%;
    margin: 0 auto;
    overflow: hidden;
    background-color: $white;

    .swiper-slide {
      display: flex;
      height: 100%;
      flex-direction: row;
      align-items: center;
      gap: 1.5rem;

      img {
        width: auto;
        height: 150px;
        max-width: 50%;
        max-height: 100%;
        border: 0;

        @include respond-to(zero, large) {
          display: none !important;
        }
      }
      .text {
        margin-top: 1rem;
        p {
          margin-right: 1rem;
        }
      }
      @include respond-to(zero, large) {
        gap: .5rem;
        margin-top: .2em;

        .text {
          margin: 0 1rem;
          p {
            font-size: $text-small;
            margin: 0;
          }
        }
      }
      @include respond-to(zero, small) {
        h2 {
          font-size: $title-x-small;
        }
        strong {
          font-size: $text-small;
        }
        .text {
          margin: 0 .5rem;
        }
      }
    }
  }
}

.prev-button {
  top: 50%;
  left: 2rem;
  position: absolute;
  transform: translateY(-50%);
  background-image: url("/assets/images/arrow-left-blue.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 30px;
  width: 40px;
  cursor: pointer;
  outline: none;
  opacity: 1;
  transition: opacity .5s;

  // Responsive
  @include respond-to(zero, medium) {
    left: 0;
  }
  @include respond-to(zero, large) {
    margin-left: .5rem;
  }
}
.next-button {
  top: 50%;
  right: 2rem;
  position: absolute;
  transform: translateY(-50%);
  background-image: url("/assets/images/arrow-right-blue.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 30px;
  width: 40px;
  cursor: pointer;
  outline: none;
  opacity: 1;
  transition: opacity .5s;

  // Responsive
  @include respond-to(zero, medium) {
    right: 0;
  }
  @include respond-to(zero, large) {
    margin-right: .5rem;
  }
}
.button-container {
    display: flex;
    margin-top: 4rem;
}
.prev-button.swiper-button-disabled {
    opacity: .6;
}
.next-button.swiper-button-disabled {
    opacity: .6;
}
.prev-button.swiper-button-disabled ~ .next-button:not(.swiper-button-disabled) {
    animation: 2s linear 1s infinite alternate size;
}
.prev-button:not(.swiper-button-disabled) {
    .next-button.swiper-button-disabled ~ & {
        animation: 2s linear 1s infinite alternate size;
    }
}

@keyframes size {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}