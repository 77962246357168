/** MDBBottstrap MDBFileInput **/
.file-field {
    position: relative
  }
  
  .file-field .file-path-wrapper {
    height: 2.5rem;
    padding-left: 10px;
    overflow: hidden
  }
  
  .file-field input.file-path {
    width: 100%;
    height: 36px
  }
  
  .file-field .btn {
    float: left
  }
  
  .file-field span {
    cursor: pointer
  }
  
  .file-field input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    cursor: pointer;
    filter: alpha(opacity=0);
    opacity: 0
  }
  
  .file-field input[type="file"]::-webkit-file-upload-button {
    display: none
  }
  
  .range-field {
    position: relative
  }
  
  .range-field input[type="range"] {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 15px 0;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    border: 1px solid #fff;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
  }
  
  .range-field input[type="range"]:focus {
    outline: 0
  }
  
  .range-field input[type="range"]+.thumb {
    position: absolute;
    top: 10px;
    width: 0;
    height: 0;
    background-color: #4285f4;
    border: 0;
    border-radius: 50%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%
  }
  
  .range-field input[type="range"]+.thumb .value {
    display: block;
    width: 30px;
    font-size: 0;
    color: #4285f4;
    text-align: center;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
  }
  
  .range-field input[type="range"]+.thumb.active {
    border-radius: 50% 50% 50% 0
  }
  
  .range-field input[type="range"]+.thumb.active .value {
    margin-top: 8px;
    margin-left: -1px;
    font-size: 10px;
    color: #fff
  }
  
  .range-field input[type="range"]::-webkit-slider-runnable-track {
    height: 3px;
    background: #c2c0c2;
    border: 0
  }
  
  .range-field input[type="range"]::-webkit-slider-thumb {
    width: 14px;
    height: 14px;
    margin: -5px 0 0 0;
    background-color: #4285f4;
    border: 0;
    border-radius: 50%;
    -webkit-transition: .3s;
    transition: .3s;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-appearance: none;
    appearance: none
  }
  
  .range-field input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #ccc
  }
  
  .range-field input[type="range"]::-moz-range-track {
    height: 3px;
    background: #c2c0c2;
    border: 0
  }
  
  .range-field input[type="range"]::-moz-range-thumb {
    width: 14px;
    height: 14px;
    margin-top: -5px;
    background: #4285f4;
    border: 0;
    border-radius: 50%
  }
  
  .range-field input[type="range"]:-moz-focusring {
    outline: 1px solid #fff;
    outline-offset: -1px
  }
  
  .range-field input[type="range"]:focus::-moz-range-track {
    background: #c2c0c2
  }
  
  .range-field input[type="range"]::-ms-track {
    height: 3px;
    color: transparent;
    background: transparent;
    border-color: transparent;
    border-width: 6px 0
  }
  
  .range-field input[type="range"]::-ms-fill-lower {
    background: #c2c0c2
  }
  
  .range-field input[type="range"]::-ms-fill-upper {
    background: #c2c0c2
  }
  
  .range-field input[type="range"]::-ms-thumb {
    width: 14px;
    height: 14px;
    background: #4285f4;
    border: 0;
    border-radius: 50%
  }
  
  .range-field input[type="range"]:focus::-ms-fill-lower {
    background: #c2c0c2
  }
  
  .range-field input[type="range"]:focus::-ms-fill-upper {
    background: #c2c0c2
  }
  
  [type='radio']:not(:checked),
  [type='radio']:checked {
    position: absolute;
    pointer-events: none;
    opacity: 0;
  }
  
  /** MDBInput type="checkbox" **/
  .form-check-input[type='radio']:not(:checked) + label,
  .form-check-input[type='radio']:checked + label,
  label.btn input[type='radio']:not(:checked) + label,
  label.btn input[type='radio']:checked + label {
    position: relative;
    display: inline-block;
    height: 1.5625rem;
    padding-left: 35px;
    line-height: 1.5625rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: 0.28s ease;
    transition: 0.28s ease;
  }
  
  .form-check-input[type='radio'] + label:before,
  .form-check-input[type='radio'] + label:after,
  label.btn input[type='radio'] + label:before,
  label.btn input[type='radio'] + label:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 16px;
    height: 16px;
    margin: 4px;
    content: '';
    -webkit-transition: 0.28s ease;
    transition: 0.28s ease;
  }
  
  .form-check-input[type='radio']:not(:checked) + label:before,
  .form-check-input[type='radio']:not(:checked) + label:after,
  .form-check-input[type='radio']:checked + label:before,
  .form-check-input[type='radio']:checked + label:after,
  .form-check-input[type='radio'].with-gap:checked + label:before,
  .form-check-input[type='radio'].with-gap:checked + label:after,
  label.btn input[type='radio']:not(:checked) + label:before,
  label.btn input[type='radio']:not(:checked) + label:after,
  label.btn input[type='radio']:checked + label:before,
  label.btn input[type='radio']:checked + label:after,
  label.btn input[type='radio'].with-gap:checked + label:before,
  label.btn input[type='radio'].with-gap:checked + label:after {
    border-radius: 50%;
  }
  
  .form-check-input[type='radio']:not(:checked) + label:before,
  .form-check-input[type='radio']:not(:checked) + label:after,
  label.btn input[type='radio']:not(:checked) + label:before,
  label.btn input[type='radio']:not(:checked) + label:after {
    border: 2px solid #5a5a5a;
  }
  
  .form-check-input[type='radio']:not(:checked) + label:after,
  label.btn input[type='radio']:not(:checked) + label:after {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  
  .form-check-input[type='radio']:checked + label:before,
  label.btn input[type='radio']:checked + label:before {
    border: 2px solid transparent;
  }
  
  .form-check-input[type='radio']:checked + label:after,
  .form-check-input[type='radio'].with-gap:checked + label:before,
  .form-check-input[type='radio'].with-gap:checked + label:after,
  label.btn input[type='radio']:checked + label:after,
  label.btn input[type='radio'].with-gap:checked + label:before,
  label.btn input[type='radio'].with-gap:checked + label:after {
    border: 2px solid $primary;
  }
  
  .form-check-input[type='radio']:checked + label:after,
  .form-check-input[type='radio'].with-gap:checked + label:after,
  label.btn input[type='radio']:checked + label:after,
  label.btn input[type='radio'].with-gap:checked + label:after {
    background-color: $primary;
  }
  
  .form-check-input[type='radio']:checked + label:after,
  label.btn input[type='radio']:checked + label:after {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  
  .form-check-input[type='radio'].with-gap:checked + label:after,
  label.btn input[type='radio'].with-gap:checked + label:after {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  
  .form-check-input[type='radio'].with-gap:disabled:checked + label:before,
  label.btn input[type='radio'].with-gap:disabled:checked + label:before {
    border: 2px solid rgba(0, 0, 0, 0.46);
  }
  
  .form-check-input[type='radio'].with-gap:disabled:checked + label:after,
  label.btn input[type='radio'].with-gap:disabled:checked + label:after {
    background-color: rgba(0, 0, 0, 0.46);
    border: 0;
  }
  
  .form-check-input[type='radio']:disabled:not(:checked) + label:before,
  .form-check-input[type='radio']:disabled:checked + label:before,
  label.btn input[type='radio']:disabled:not(:checked) + label:before,
  label.btn input[type='radio']:disabled:checked + label:before {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.46);
  }
  
  .form-check-input[type='radio']:disabled + span,
  label.btn input[type='radio']:disabled + span {
    color: rgba(0, 0, 0, 0.46);
  }
  
  .form-check-input[type='radio']:disabled:not(:checked) + span:before,
  label.btn input[type='radio']:disabled:not(:checked) + span:before {
    border-color: rgba(0, 0, 0, 0.46);
  }
  
  .form-check-input[type='radio']:disabled:checked + span:after,
  label.btn input[type='radio']:disabled:checked + span:after {
    background-color: rgba(0, 0, 0, 0.46);
    border-color: #bdbdbd;
  }
  
  .form-check-input[type='radio']:checked + label:after .disabled-material,
  label.btn input[type='radio']:checked + label:after .disabled-material {
    background-color: rgba(66, 133, 244, 0.2);
  }
  
  .form-check-input[type='radio'] + label.form-check-label {
    margin: 0 35px 0 0 !important;
  }
  
  /** Checkbox **/
  [type='checkbox']:not(:checked),
  [type='checkbox']:checked {
    position: absolute;
    pointer-events: none;
    opacity: 0;
  }
  .form-check-input[type='checkbox'] + label,
  label.btn input[type='checkbox'] + label {
    position: relative;
    display: inline-block;
    height: 1.5625rem;
    padding-left: 35px;
    line-height: 1.5625rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .form-check-input[type='checkbox'] + label.form-check-label-left,
  label.btn input[type='checkbox'] + label.form-check-label-left {
    padding: 0 35px 0 0 !important;
  }
  
  .form-check-input[type='checkbox'] + label.form-check-label-left:before,
  label.btn input[type='checkbox'] + label.form-check-label-left:before {
    right: 0;
    left: 100% !important;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  
  .form-check-input[type='checkbox'] + label:before,
  .form-check-input[type='checkbox']:not(.filled-in) + label:after,
  label.btn input[type='checkbox'] + label:before,
  label.btn input[type='checkbox']:not(.filled-in) + label:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 18px;
    height: 18px;
    margin-top: 3px;
    content: '';
    border: 2px solid #8a8a8a;
    border-radius: 1px;
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }
  
  .form-check-input[type='checkbox']:not(.filled-in) + label:after,
  label.btn input[type='checkbox']:not(.filled-in) + label:after {
    border: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  
  .form-check-input[type='checkbox']:not(:checked):disabled + label:before,
  label.btn input[type='checkbox']:not(:checked):disabled + label:before {
    background-color: #bdbdbd;
    border: none;
  }
  
  .form-check-input[type='checkbox']:checked + label:before,
  label.btn input[type='checkbox']:checked + label:before {
    top: -4px;
    left: -5px;
    width: 12px;
    height: 1.375rem;
    border-top: 2px solid transparent;
    border-right: 2px solid $primary;
    border-bottom: 2px solid $primary;
    border-left: 2px solid transparent;
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .form-check-input[type='checkbox']:checked + label.form-check-label-left:before,
  label.btn input[type='checkbox']:checked + label.form-check-label-left:before {
    -webkit-transform: translateX(0) rotateZ(40deg);
    transform: translateX(0) rotateZ(40deg);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
  
  .form-check-input[type='checkbox']:checked:disabled + label:before,
  label.btn input[type='checkbox']:checked:disabled + label:before {
    border-right: 2px solid #bdbdbd;
    border-bottom: 2px solid #bdbdbd;
  }
  
  .form-check-input[type='checkbox']:indeterminate + label:before,
  label.btn input[type='checkbox']:indeterminate + label:before {
    top: -11px;
    left: -12px;
    width: 10px;
    height: 1.375rem;
    border-top: none;
    border-right: 2px solid $primary;
    border-bottom: none;
    border-left: none;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .form-check-input[type='checkbox']:indeterminate + label.form-check-label-left:before,
  label.btn input[type='checkbox']:indeterminate + label.form-check-label-left:before {
    top: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
  
  .form-check-input[type='checkbox']:indeterminate:disabled + label:before,
  label.btn input[type='checkbox']:indeterminate:disabled + label:before {
    background-color: transparent;
    border-right: 2px solid rgba(0, 0, 0, 0.46);
  }
  
  .form-check-input[type='checkbox'].filled-in + label:after,
  label.btn input[type='checkbox'].filled-in + label:after {
    border-radius: 0.125rem;
  }
  
  .form-check-input[type='checkbox'].filled-in + label:before,
  .form-check-input[type='checkbox'].filled-in + label:after,
  label.btn input[type='checkbox'].filled-in + label:before,
  label.btn input[type='checkbox'].filled-in + label:after {
    position: absolute;
    left: 0;
    z-index: 1;
    content: '';
    -webkit-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
    transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  }
  
  .form-check-input[type='checkbox'].filled-in:not(:checked) + label:before,
  label.btn input[type='checkbox'].filled-in:not(:checked) + label:before {
    top: 10px;
    left: 6px;
    width: 0;
    height: 0;
    border: 3px solid transparent;
    -webkit-transform: rotateZ(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  
  .form-check-input[type='checkbox'].filled-in:not(:checked) + label:after,
  label.btn input[type='checkbox'].filled-in:not(:checked) + label:after {
    top: 0;
    z-index: 0;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: 2px solid #5a5a5a;
  }
  
  .form-check-input[type='checkbox'].filled-in:checked + label:before,
  label.btn input[type='checkbox'].filled-in:checked + label:before {
    top: 0;
    left: 1px;
    width: 8px;
    height: 13px;
    border-top: 2px solid transparent;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-left: 2px solid transparent;
    -webkit-transform: rotateZ(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  
  .form-check-input[type='checkbox'].filled-in:checked + label:after,
  label.btn input[type='checkbox'].filled-in:checked + label:after {
    top: 0;
    z-index: 0;
    width: 20px;
    height: 20px;
    background-color: #a6c;
    border: 2px solid #a6c;
  }
  
  .form-check-input[type='checkbox'].filled-in.filled-in-danger:checked + label:after,
  label.btn input[type='checkbox'].filled-in.filled-in-danger:checked + label:after {
    background-color: #f44336;
    border-color: #f44336;
  }
  
  .form-check-input[type='checkbox']:disabled:not(:checked) + label:before,
  label.btn input[type='checkbox']:disabled:not(:checked) + label:before {
    background-color: #bdbdbd;
    border-color: #bdbdbd;
  }
  
  .form-check-input[type='checkbox']:disabled:not(:checked) + label:after,
  label.btn input[type='checkbox']:disabled:not(:checked) + label:after {
    background-color: #bdbdbd;
    border-color: #bdbdbd;
  }
  
  .form-check-input[type='checkbox']:disabled:checked + label:before,
  label.btn input[type='checkbox']:disabled:checked + label:before {
    background-color: transparent;
  }
  
  .form-check-input[type='checkbox']:disabled:checked + label:after,
  label.btn input[type='checkbox']:disabled:checked + label:after {
    background-color: #bdbdbd;
    border-color: #bdbdbd;
  }
  
  /** MDBSelect **/
  
  .select-wrapper .select-dropdown {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
  }
  
  .select-label {
    position: absolute
  }
  
  .select-wrapper {
    position: relative
  }
  
  .select-wrapper:not(.md-outline) .select-dropdown:focus {
    border-bottom: 1px solid #4285f4;
    -webkit-box-shadow: 0 1px 0 0 #4285f4;
    box-shadow: 0 1px 0 0 #4285f4
  }
  
  .select-wrapper.active span.caret,.select-wrapper.active label.mdb-main-label {
    color: #007bff
  }
  
  .select-wrapper.active+label {
    color: #007bff
  }
  
  .select-wrapper input::-moz-selection {
    background: transparent
  }
  
  .select-wrapper input::selection {
    background: transparent
  }
  
  .select-wrapper input.select-dropdown {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    height: 38px;
    padding: 0;
    margin: 0 0 .94rem 0;
    font-size: 1rem;
    line-height: 2.9rem;
    text-overflow: ellipsis;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #ced4da;
    outline: 0
  }
  
  .select-wrapper input.select-dropdown:disabled {
    color: rgba(0,0,0,0.3);
    cursor: default;
    border-bottom-color: rgba(0,0,0,0.2)
  }
  
  .select-wrapper input.select-dropdown .selected,.select-wrapper input.select-dropdown li:focus {
    background-color: rgba(0,0,0,0.15)
  }
  
  .select-wrapper input.select-dropdown li.active {
    background: transparent
  }
  
  .select-wrapper input.select-dropdown .fas,.select-wrapper input.select-dropdown .fab,.select-wrapper input.select-dropdown .far {
    color: inherit
  }
  
  .select-wrapper input.active {
    border-bottom: 1px solid #4285f4;
    -webkit-box-shadow: 0 1px 0 0 #4285f4;
    box-shadow: 0 1px 0 0 #4285f4
  }
  
  .select-wrapper .search-wrap {
    display: block;
    padding: 1rem 0 0;
    margin: 0 .7rem
  }
  
  .select-wrapper .search-wrap .md-form {
    margin-top: 0;
    margin-bottom: 1rem
  }
  
  .select-wrapper .search-wrap .md-form input {
    padding-bottom: .4rem;
    margin-bottom: 0;
    border: 0;
    border-bottom: 1px solid #ced4da;
    border-radius: 0
  }
  
  .select-wrapper .search-wrap .md-form input:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: 0 1px 0 0 #4285f4 !important;
    box-shadow: 0 1px 0 0 #4285f4 !important
  }
  
  .select-wrapper span.caret {
    position: absolute;
    top: .8rem;
    right: 0;
    font-size: .63rem;
    color: #495057
  }
  
  .select-wrapper span.caret.disabled {
    color: rgba(0,0,0,0.3)
  }
  
  .select-wrapper+label {
    position: absolute;
    top: 2.125rem;
    font-weight: 300;
    color: #757575;
    -webkit-transition: .2s ease-out;
    transition: .2s ease-out
  }
  
  .select-wrapper+label.active {
    top: 1.5rem;
    left: 15px;
    font-size: .8rem;
    -webkit-transform: translateY(-14px);
    transform: translateY(-14px)
  }
  
  .select-wrapper+label.active-check {
    color: #4285f4
  }
  
  .select-wrapper+label.mdb-main-label {
    z-index: 1
  }
  
  .select-wrapper+label.disabled {
    color: rgba(0,0,0,0.3)
  }
  
  .select-wrapper>label.mdb-main-label {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 300;
    color: #757575;
    -webkit-transition: .2s ease-out;
    transition: .2s ease-out;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    z-index: 1
  }
  
  .select-wrapper>label.mdb-main-label.active {
    top: 0;
    left: 0;
    font-size: .8rem;
    -webkit-transform: translateY(-14px);
    transform: translateY(-14px)
  }
  
  .select-wrapper>label.mdb-main-label.disabled {
    color: rgba(0,0,0,0.3)
  }
  
  .select-wrapper.dropdown-primary>label.mdb-main-label.active {
    color: #4285f4
  }
  
  .select-wrapper.dropdown-danger>label.mdb-main-label.active {
    color: #c00
  }
  
  .select-wrapper.dropdown-default>label.mdb-main-label.active {
    color: #2bbbad
  }
  
  .select-wrapper.dropdown-secondary>label.mdb-main-label.active {
    color: #a6c
  }
  
  .select-wrapper.dropdown-success>label.mdb-main-label.active {
    color: #00c851
  }
  
  .select-wrapper.dropdown-info>label.mdb-main-label.active {
    color: #33b5e5
  }
  
  .select-wrapper.dropdown-warning>label.mdb-main-label.active {
    color: #fb3
  }
  
  .select-wrapper.dropdown-ins>label.mdb-main-label.active {
    color: #2e5e86
  }
  
  .select-wrapper.dropdown-dark>label.mdb-main-label.active {
    color: #2e2e2e
  }
  
  .select-wrapper i {
    color: rgba(0,0,0,0.3)
  }
  
  .select-wrapper ul {
    padding-left: 0;
    list-style-type: none
  }
  
  .select-wrapper.md-form>ul li label {
    top: 0;
    font-size: .9rem;
    color: #4285f4;
    -webkit-transform: none;
    transform: none
  }
  
  .select-wrapper.md-form>ul li.select-toggle-all label {
    padding-left: 38px
  }
  
  .select-wrapper.md-form.colorful-select>ul li.select-toggle-all:hover label {
    color: #fff
  }
  
  .select-wrapper.md-form.md-outline span.caret {
    padding-right: .75rem;
    padding-left: .75rem
  }
  
  .select-wrapper.md-form.md-outline span.caret.active {
    color: #4285f4 !important
  }
  
  .select-wrapper.md-form.md-outline .dropdown-content {
    top: 2.7rem !important
  }
  
  .select-wrapper.md-form.md-outline input.select-dropdown {
    padding: .375rem .75rem;
    color: #495057
  }
  
  .select-wrapper.md-form.md-outline input.select-dropdown:focus {
    border-color: #4285f4;
    -webkit-box-shadow: inset 0 0 0 1px #4285f4;
    box-shadow: inset 0 0 0 1px #4285f4
  }
  
  .select-wrapper.md-form.md-outline+label {
    position: absolute;
    top: .5em !important;
    left: 23px;
    z-index: 2 !important;
    padding-right: 5px;
    padding-left: 5px;
    font-size: 13px;
    font-weight: 500;
    background: #fff;
    -webkit-transform: translateY(40%);
    transform: translateY(40%)
  }
  
  .select-wrapper.md-form.md-outline+label.active {
    color: #4285f4
  }
  
  .select-wrapper .select-add-option {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    display: none;
    color: #4285f4;
    cursor: pointer
  }
  
  .select-wrapper ~ .invalid-feedback,.select-wrapper ~ .valid-feedback {
    margin-top: -1rem
  }
  
  select {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif
  }
  
  select.mdb-select {
    display: none !important
  }
  
  select.mdb-select ~ .btn {
    display: none !important
  }
  
  select.browser-default {
    display: block !important
  }
  
  select:disabled {
    color: rgba(0,0,0,0.3)
  }
  
  .select-dropdown [type="checkbox"]:disabled:not(:checked)+label:before {
    margin-top: 3px;
    margin-left: 0
  }
  
  .select-dropdown ul {
    padding: 0;
    list-style-type: none
  }
  
  .select-dropdown li img {
    float: right;
    width: 30px;
    height: 30px;
    margin: .3rem .75rem
  }
  
  .select-dropdown li.disabled,.select-dropdown li.disabled>span,.select-dropdown li.optgroup {
    color: rgba(0,0,0,0.3);
    cursor: context-menu;
    background-color: transparent !important
  }
  
  .select-dropdown li.optgroup {
    border-top: 1px solid #eee
  }
  
  .select-dropdown li.optgroup.selected>span {
    color: rgba(0,0,0,0.7)
  }
  
  .select-dropdown li.optgroup>span {
    color: rgba(0,0,0,0.4)
  }
  
  .multiple-select-dropdown li [type="checkbox"]+label {
    height: .63rem
  }