#spaceshuttle {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;

    .observablehq {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100vw;
        z-index: 200;
    }

    @include respond-to(zero, medium, portrait) {
        top: 4rem;
    }
    @include respond-to(zero, large, landscape) {
        bottom: 4rem;
        width: 40vw;
    }
    audio {
        display: none;
    }

    .playpause_wrapper {
        position: fixed;
        right: 6vw;
        bottom: 8vh;
        z-index: 1000;
        #pause {
            display: none;
        }
    }

    .observablehq--cellname,
    .observablehq--function,
    .observablehq--field,
    .observablehq--number,
    .observablehq--string {
        display: none;
    }
}